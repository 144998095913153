import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {


  updateTapesDropdown(event) {
    const selectedOption = event.target.value;
    const turboFrame = document.getElementById('tapes_select');
    turboFrame.id = "tapes_select";
    turboFrame.src = `/configurator/curtains/get_tapes?id=${selectedOption}`;
  }

  accessories_categories(event) {
    const selectedOption = event.target.value;
    const turboFrame = document.getElementById('accessories_categories');
    turboFrame.id = "accessories_categories";
    turboFrame.src = `/configurator/curtains/get_accessories_categories?id=${selectedOption}`;
    console.log(selectedOption);
  }

  updateDensitiesDropdown(event) {
    const selectedOption = event.target.value;
    const turboFrame = document.getElementById('densities_select');
    turboFrame.id = "densities_select";
    turboFrame.src = `/configurator/curtains/get_densities?id=${selectedOption}`;
  }

  summary(event) {
    let model = document.getElementById('model').value || null;
    let requiredFields = this.getRequiredFields(model);
    let completedValues = this.checkValues(requiredFields);
    completedValues["model"] = model;
    if (this.checkValidity(completedValues) === true) {
      const turboFrame = document.getElementById('summary');
      turboFrame.id = "summary";
      turboFrame.src = `/configurator/curtains/summary?curtain=${JSON.stringify(completedValues)}`;
    }
  }

  checkValidity(completedValues) {
    const validity = Object.entries(completedValues)
      .filter(([key, _]) => key !== "heading_tape_id")
      .filter(([key, _]) => key !== "back_fabric_id")
      .every(([_, value]) =>
        value !== null && value !== ''); // Check if all other values are not null
    return Object.keys(completedValues).length > 0 ? validity : false; //returns true if the dictonary is empty but it should be false
  }

  checkValues(requiredFields) {
    let dictionary = {};
    requiredFields.forEach((key) => {
      dictionary[key] = document.getElementById(key) ? document.getElementById(key).value : null;
    });
    return dictionary;
  }

  getRequiredFields(model) { 
    let fields =  ["model","quantity","density", "name", "workmanship_id", "heading_tape_id", "mt", "ml", "mb", "mr", "back_fabric_id"]; 
    switch (model) {
      case "S1":
        fields.push("curtain_s1_H", "curtain_s1_L", "fabric1Id");
        break;
      case "S2":
        fields.push("curtain_s2_H", "curtain_s2_L", "fabric1Id");
        break;
      case "A1":
        fields.push("curtain_a1_H", "curtain_a1_L1", "curtain_a1_L2", "fabric1Id", "fabric2Id");
        break;
      case "A2":
        fields.push("curtain_a2_H", "curtain_a2_L1", "curtain_a2_L2", "fabric1Id", "fabric2Id");
        break;  
      case "B1":
        fields.push("curtain_b1_H", "curtain_b1_L1", "curtain_b1_L2", "curtain_b1_L3", "fabric1Id", "fabric2Id", "fabric3Id");
        break;
      case "B2":
        fields.push("curtain_b2_H", "curtain_b2_L1", "curtain_b2_L2", "curtain_b2_L3", "fabric1Id", "fabric2Id", "fabric3Id");
        break;
      case "C1":
        fields.push("curtain_c1_L", "curtain_c1_H1", "curtain_c1_H2", "curtain_c1_H3", "fabric1Id", "fabric2Id", "fabric3Id");
        break;
      case "C2":
        fields.push("curtain_c2_L", "curtain_c2_H1", "curtain_c2_H2", "curtain_c2_H3", "fabric1Id", "fabric2Id", "fabric3Id");
        break;
      case "D1":
        fields.push("curtain_d1_L", "curtain_d1_H1", "curtain_d1_H2", "fabric1Id", "fabric2Id");
        break;
      case "D2":
        fields.push("curtain_d2_L", "curtain_d2_H1", "curtain_d2_H2", "fabric1Id", "fabric2Id");
        break;
      default:
        fields = [];
    };
    return fields;
  }

  handleChange(event) {
    const frame = document.getElementById('tapes_select');
    const elem = event.target.value;
    if (elem == 1) {
      document.getElementById('double').style.display = 'none';
      document.getElementById('single').style.display = 'block';
    } else {
      document.getElementById('double').style.display = 'block';
      document.getElementById('single').style.display = 'none';
    }
  }

  modelSelect(event) { 
    const forms = ['S1-form', 'S2-form', 'A1-form', 'A2-form', 'B1-form', 'B2-form', 'C1-form', 'C2-form', 'D1-form', 'D2-form'];
    forms.forEach((form) => {
      //hide each div in the array
      document.getElementById(form).style.display = 'none';
    });
    let formName = event.target.value + "-form";
    document.getElementById(formName).style.display = 'block';  
    document.getElementById('model').value = event.target.value;
  }

  accessories_categories(event) {
    const category = event.target.value;
    const turboFrame = document.getElementById('accessories');
    turboFrame.id = "accessories";
    turboFrame.src = `/configurator/curtains/get_accesories_categories?category_id=${category}`;
  }

  get_accessories(event) {
    const category = event.target.value;
    const turboFrame = document.getElementById('accessories_products');
    turboFrame.id = "accessories_products";
    turboFrame.src = `/configurator/curtains/get_accesories?category_id=${category}`;
  }

  refreshAccessories(event) {
    this.summary(event);
  }
}



